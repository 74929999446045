import React from "react"

import Layoutcs from "../../../components/layoutcs/layoutcs"
import SEO from "../../../components/seo"

import Container from 'react-bootstrap/Container'

import Bloglist from "../../../components/bloglist/bloglist"

import "../../index.scss"
import "../../blog.scss"

import ImgBlogPost from "../../../images/blogpost/podcast-o-aplikaci-springaudit.jpg"

const Blog = () => (

	<Layoutcs>
		<SEO title="Podcast o aplikaci Springaudit" description="Co je to aplikace SPRINGaudit? Dá se pronajmout? Co vše může být předmětem auditu? Jak probíhá nastavení auditního projektu? A jak dlouho trvá?" />

		<Container className="ftrs-content pb-4 ftrs-blogpost">

			<h1 className="ftrs-h1">Podcast o aplikaci Springaudit</h1>
			<div className="ftrs-blogpost__date">28.07.2022</div>

			<img className="ftrs-blogpost__img" src={ImgBlogPost} alt="Podcast o aplikaci Springaudit" />

		</Container>

		<div className="ftrs-blogpost__text">
			<Container>

				<p>Jak vnímáme audity u nás v&nbsp;Market&nbsp;Vision? Jaké typy auditů nabízíme? Co je to aplikace SPRINGaudit? Dá&nbsp;se pronajmout? Co&nbsp;vše může být předmětem auditu? Jak&nbsp;probíhá nastavení auditního projektu? A&nbsp;jak dlouho trvá?</p>
				
				<p>O tom všem jsme si povídali s&nbsp;naším senior consultantem Michalem Skalickým v&nbsp;rámci prvního dílu série Podcastů z&nbsp;naší pražské centrály. Michal s&nbsp;námi spolupracuje již patnáct let a&nbsp;kromě stovek realizovaných projektů se podílí právě i&nbsp;na&nbsp;vývoji aplikace SPRINGaudit.</p>

				<p>Otázky na téma auditních služeb mu kladl náš další dlouholetý kolega Aleš Holakovský, který Vás bude novou sérií podcastů na&nbsp;různá témata z&nbsp;našich aktivit, oborů a&nbsp;služeb provázet.</p>

				<iframe className="mt2 mb2" src="https://anchor.fm/marketvision/embed/episodes/MARKET-VISION-PODCAST-1-O-aplikaci-SPRINGaudit-a-auditnch-slubch-s-Michalem-Skalickm-e1jqao9" height="auto" width="100%" frameborder="0" scrolling="no" title="podcast"></iframe>

				<p>Více informací naleznete též na <a className="ftrs-blacklink" href="https://www.springaudit.com" target="_blank" rel="noreferrer">www.springaudit.com</a> a <a className="ftrs-blacklink" href="https://www.marketvision.cz/audit-prodejni-site" target="_blank" rel="noreferrer">www.marketvision.cz/audit-prodejni-site</a></p>

				<p>Poslouchejte a sledujte MARKET VISION PODCASTY přes <a className="ftrs-blacklink" href="https://www.youtube.com/watch?v=DGwrelWZFag" target="_blank" rel="noreferrer">Youtube</a> , <a className="ftrs-blacklink" href="https://open.spotify.com/show/2fbwIgdFGcTz91i2mpI2eU" target="_blank" rel="noreferrer">Spotify</a> nebo <a className="ftrs-blacklink" href="https://anchor.fm/marketvision/episodes/MARKET-VISION-PODCAST-1-O-aplikaci-SPRINGaudit-a-auditnch-slubch-s-Michalem-Skalickm-e1jqao9" target="_blank" rel="noreferrer">Anchor</a>.</p>

			</Container>
		</div>

		<Container className="pt-4 pb-4">
			<Bloglist></Bloglist>
		</Container>

	</Layoutcs>

)

export default Blog